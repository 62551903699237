// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyB6eIZrVmYjqqNgggBbtO8mnoPwXE4ro7c',
    authDomain: 'bakrabazaar-e1708.firebaseapp.com',
    projectId: 'bakrabazaar-e1708',
    storageBucket: 'bakrabazaar-e1708.appspot.com',
    messagingSenderId: '683607286601',
    appId: '1:683607286601:web:6e27e8dfe353d09d377098',
    measurementId: 'G-JNYG9GV65E'
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  google_maps_api_key: 'AIzaSyA3QjyaSbG7wrFjBr3LaGcPytbk2s2Toak',
  bannerAdId: 'ca-app-pub-3125614403998708/6882930860',
  interstitialAdId: 'ca-app-pub-3125614403998708/4769254484',
  bannerAdIdiOS: '',
  interstitialAdIdiOS: '',
  rewardedAdIdIos: '',
  rewardedAdId: 'ca-app-pub-3125614403998708/2087250823',
  langArr: [
    { name: 'English', code: 'en' }
  ],
  cashFreeMode:'production',
  apiUrl:'https://hfz1sep92m.execute-api.us-east-1.amazonaws.com/dev'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

// local-storage.service.ts

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private storageKey = 'location';
  private stateKey = 'state';
  private dataSubject = new BehaviorSubject<any>(null);

  constructor() {
    // Initialize data from local storage
    this.loadFromLocalStorage();

    // Listen for changes in local storage
    window.addEventListener('storage', () => {
      this.loadFromLocalStorage();
    });
  }

  getDataObservable() {
    return this.dataSubject.asObservable();
  }

  setData(data: any) {
    localStorage.setItem(this.storageKey, data);
    this.dataSubject.next(data);
  }
  setState(data: any) {
    localStorage.setItem(this.stateKey, data);
    this.dataSubject.next(data);
  }

  private loadFromLocalStorage() {
    const data = localStorage.getItem(this.storageKey);
    if (data) {
      this.dataSubject.next(data);
    }
  }


}

import { Injectable } from '@angular/core';
import {
  Auth,
  RecaptchaVerifier,
  User,
  UserCredential,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  signOut
} from '@angular/fire/auth';
import { Firestore, doc, setDoc } from '@angular/fire/firestore';
import { sendEmailVerification } from 'firebase/auth';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = false;
  appVerifier: any;
  confirmationResult: any;
  constructor(private readonly auth: Auth, private readonly firestore: Firestore) {}

  checkLoggedIn(): boolean {
    // Implement your logic to check if the user is logged in
    if(this.getUser()){
      this.isLoggedIn = true;
    }
    return this.isLoggedIn;
  }

  getUser(): User {
    return this.auth.currentUser;
  }

  getUser$(): Observable<User> {
    return of(this.getUser());
  }

  login(email: string, password: string): Promise<UserCredential> {
     return signInWithEmailAndPassword(this.auth, email, password);
  }

  async signup(email: string, password: string, displayName: string, phoneNumber: string): Promise<UserCredential> {
    try {
      const newUserCredential: UserCredential = await createUserWithEmailAndPassword(this.auth, email, password);
      await sendEmailVerification(newUserCredential.user);
      const userProfileDocumentReference = doc(this.firestore, `users/${newUserCredential.user.uid}`);
      // localStorage.setItem('uid', newUserCredential.user.uid);
      // localStorage.setItem('isLoggedIn','true');
      await setDoc(userProfileDocumentReference, {
        email,
        displayName,
        photoURL: 'assets/avatar.gif',
        phoneNumber,
        following: [],
        followers: [],
        wishlist: [],
        block: false,
        type: 'users',
        joined: Date.now(),
        online: true,
        lastSeen: Date.now(),
        typing: false,
        fcm_token: '',
        package:'trial',
      });
      return newUserCredential;
    } catch (error) {
      throw error;
    }
  }

  resetPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email);
  }

  logout(): Promise<void> {
    return signOut(this.auth);
  }

  recaptcha() {
    this.appVerifier = new RecaptchaVerifier('sign-in-button', {
      size: 'invisible',
      callback: (response) => {
        // console.log(response);
      },
      'expired-callback': () => {}
    }, this.auth);
  }

  async signInWithPhoneNumber(phoneNumber) {
    try {
      if(!this.appVerifier) {this.recaptcha();}
      const confirmationResult = await signInWithPhoneNumber(this.auth, phoneNumber, this.appVerifier);
      this.confirmationResult = confirmationResult;
      return confirmationResult;
    } catch(e) {
      throw(e);
    }
  }

  async verifyOtp(otp): Promise<UserCredential> {
    try {
      if(!this.appVerifier) {this.recaptcha();}
      const newUserCredential = await this.confirmationResult.confirm(otp);
      // console.log({newUserCredential});
      const userProfileDocumentReference = doc(this.firestore, `users/${newUserCredential.user.uid}`);
      // eslint-disable-next-line no-underscore-dangle
      // console.log(newUserCredential._tokenResponse);
      // eslint-disable-next-line no-underscore-dangle
      if(newUserCredential._tokenResponse.isNewUser){
        await setDoc(userProfileDocumentReference, {
          providerId: newUserCredential.providerId,
          photoURL: 'assets/avatar.gif',
          phoneNumber: newUserCredential.user.phoneNumber,
          following: [],
          followers: [],
          wishlist: [],
          block: false,
          type: 'users',
          joined: Date.now(),
          online: true,
          lastSeen: Date.now(),
          package:'trial',
          typing: false,
          fcm_token: '',
        });
      }
      return newUserCredential;
    } catch(e) {
      throw(e?.message);
    }
  }

}
